<template>
  <v-container>
    <v-row>
      <v-col
        cols="12"
        :md="showHelp ? 8 : 11"
        v-show="!showExpand"
        :sm="12"
        class="pr-0 py-0"
      >
        <GoBackBtn :routeToGo="routeToGo" :tabId="false" />
        <PageHeader :title="title" class="pb-1" />
        <v-card>
          <v-data-table
            :headers="headers"
            :items="procesosActualizacion"
            class="elevation-1"
            :search="search"
            :loading="loading"
            show-expand
            :expanded.sync="expanded"
            item-key="procId"
          >
            <template v-slot:[`item.anulado`]="{ item }">
              <v-icon small color="primary">
                {{ item.fechaAnula != null ? checkIcon : "" }}
              </v-icon>
            </template>
            <template v-slot:top>
              <v-toolbar flat>
                <v-row>
                  <v-col cols="8">
                    <v-text-field
                      v-model="search"
                      :append-icon="searchIcon"
                      label="Buscar"
                      single-line
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <v-col cols="4" align="end">
                    <v-btn
                      v-if="canCreateProceso"
                      color="primary"
                      class="to-right"
                      @click="goToNuevoProcesoActualizacion()"
                    >
                      Nuevo
                    </v-btn>
                  </v-col>
                </v-row>
              </v-toolbar>
            </template>
            <template v-slot:[`item.data-table-expand`]="{ item, isExpanded }">
              <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    @click="handleExpansion(item, isExpanded)"
                    v-bind="attrs"
                    size="20"
                    v-on="on"
                    >{{ "mdi-chevron-down" }}</v-icon
                  >
                </template>
                <span>Ver filtros aplicados</span>
              </v-tooltip>
            </template>
            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length">
                <v-row>
                  <v-col cols="12" md="12">
                    <strong>Filtros aplicados:</strong>
                    {{ item.filtrosAplicados }}
                  </v-col>
                </v-row>
              </td>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    size="20"
                    v-on="on"
                    :disabled="item.fechaAnula != null"
                    @click="toggleModalSeeProcesoActualizacion(item.procId)"
                  >
                    {{ seeIcon }}
                  </v-icon>
                </template>
                <span>Ver detalle</span>
              </v-tooltip>
              <v-tooltip left v-if="puedeAnular">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    size="20"
                    v-on="on"
                    :disabled="item.fechaAnula != null"
                    @click="anularProceso(item.procId)"
                  >
                    {{ anularIcon }}
                  </v-icon>
                </template>
                <span>Anular proceso</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
      <!-- Texto de ayuda principal -->
      <v-col
        cols="12"
        :md="!showHelp ? 1 : showExpand ? 12 : 4"
        class="text-right pt-5 px-0"
        v-if="showIcon"
      >
        <Ayuda
          :optionCode="optionCode"
          :pantallaTitle="title"
          @toggleExpand="showExpand = !showExpand"
          @toggleHelp="toggleHelp"
          @toggleIcon="toggleIcon()"
        />
      </v-col>
    </v-row>
    <DeleteDialog
      :titleProp="titleDelete"
      :maxWidth="'35%'"
      :confirmButtonText="'Anular'"
      :openDelete.sync="showDeleteModal"
      :isLoading="loadingBtnDelete"
      @onDeleteItem="confirmAnularProceso()"
    />
    <v-dialog
      v-if="modalVerDetalleActualizacion"
      v-model="modalVerDetalleActualizacion"
      max-width="85%"
      @keydown.esc="toggleModalSeeProcesoActualizacion"
      persistent
    >
      <VerDetalleActualizacionImpFijos
        :procesoId="procesoId"
        @toggleModalSeeProcesoActualizacion="toggleModalSeeProcesoActualizacion"
      ></VerDetalleActualizacionImpFijos
    ></v-dialog>
  </v-container>
</template>

<script>
import GoBackBtn from "@/components/shared/GoBackBtn";
import PageHeader from "@/components/ui/PageHeader";
import DeleteDialog from "@/components/shared/DeleteDialog";
import Ayuda from "@/components/shared/Ayuda.vue";
import VerDetalleActualizacionImpFijos from "@/components/modules/cuotas/devengamientos/VerDetalleActualizacionImpFijos.vue";
import enums from "@/utils/enums/index.js";
import rules from "@/utils/helpers/rules";
import { mask } from "vue-the-mask";
import { mapActions } from "vuex";
export default {
  name: "ProcesoActualizacionImportesFijos",
  directives: { mask },
  components: {
    PageHeader,
    GoBackBtn,
    DeleteDialog,
    VerDetalleActualizacionImpFijos,
    Ayuda
  },
  data() {
    return {
      rules: rules,
      routeToGo: "ImportesFijosPorGrupoFliar",
      title: enums.titles.ACTUALIZACION_IMPORTES_FIJOS,
      titleDelete: "¿Desea anular el proceso?",
      search: "",
      searchIcon: enums.icons.SEARCH,
      anularIcon: enums.icons.CIRCLE_NONE,
      checkIcon: enums.icons.CHECK_OUTLINE,
      seeIcon: enums.icons.SEE,
      optionCode: enums.webSiteOptions.ACTUALIZACION_IMPORTES_FIJOS_DEVENGAR,
      procesoId: null,
      idToDelete: null,
      canCreateProceso: false,
      puedeAnular: false,
      loading: false,
      showDeleteModal: false,
      loadingBtnDelete: false,
      modalVerDetalleActualizacion: false,
      showExpand: false,
      showHelp: false,
      showIcon: true,
      procesosActualizacion: [],
      expanded: [],
      headers: [
        {
          text: "N° proceso",
          align: "start",
          value: "procId",
          sortable: false
        },
        {
          text: "Fecha alta",
          align: "start",
          value: "fechaAlta",
          sortable: false
        },
        {
          text: "Ususario alta",
          align: "start",
          value: "usuarioAlta",
          sortable: false
        },
        {
          text: "Anulado",
          align: "center",
          value: "anulado",
          sortable: false
        },
        {
          text: "Fecha anulado",
          align: "center",
          value: "fechaAnula",
          sortable: false
        },
        {
          text: "Usuario anula",
          align: "start",
          value: "usuarioAnula",
          sortable: false
        },
        { text: "Acciones", value: "actions", sortable: false, align: "end" },
        { text: "", value: "data-table-expand" }
      ]
    };
  },
  created() {
    this.getProcesosActualizacion();
  },
  mounted() {
    this.allowedActions = JSON.parse(localStorage.getItem("allowedActions"));
    this.setPermisos();
  },
  methods: {
    ...mapActions({
      getProcesoActualizacionImportesFijos:
        "devengamientos/getProcesoActualizacionImportesFijos",
      anulaImporteFijo: "devengamientos/anulaImporteFijo",
      setAlert: "user/setAlert"
    }),
    goToNuevoProcesoActualizacion() {
      this.$router.push({
        name: "NuevoProcesoActualizacionImportesFijos"
      });
      this.$store.dispatch("user/changeAppLoading", {
        status: true,
        text: "",
        opaque: true
      });
    },
    async getProcesosActualizacion() {
      this.loading = true;
      const response = await this.getProcesoActualizacionImportesFijos();
      this.procesosActualizacion = response;
      this.loading = false;
    },
    setPermisos() {
      this.allowedActions?.forEach(x => {
        switch (x.optionCode) {
          case enums.modules.adminSistema.permissions
            .NUEVA_ACTUALIZACION_IMPORTES_FIJOS:
            this.canCreateProceso = true;
            break;
          case enums.modules.adminSistema.permissions
            .ANULAR_ACTUALIZACION_IMPORTES_FIJOS:
            this.puedeAnular = true;
            break;
          default:
            break;
        }
      });
    },
    handleExpansion(item, state) {
      const itemIndex = this.expanded.indexOf(item);
      state ? this.expanded.splice(itemIndex, 1) : this.expanded.push(item);
    },
    anularProceso(id) {
      this.showDeleteModal = true;
      this.idToDelete = id;
    },
    async confirmAnularProceso() {
      this.loadingBtnDelete = true;
      try {
        const response = await this.anulaImporteFijo(this.idToDelete);
        if (response.status === 200) {
          this.showDeleteModal = false;
          this.setAlert({ type: "success", message: "Anulado con éxito." });
          this.getProcesosActualizacion();
          this.loadingBtnDelete = false;
        }
      } catch (error) {
        this.loadingBtnDelete = false;
      }
    },
    toggleModalSeeProcesoActualizacion(id) {
      this.procesoId = id;
      this.modalVerDetalleActualizacion = !this.modalVerDetalleActualizacion;
    },
    toggleHelp() {
      this.showHelp = !this.showHelp;
      this.showHelp ? [] : (this.showExpand = false);
    },
    toggleIcon() {
      this.showIcon = true;
    }
  }
};
</script>
