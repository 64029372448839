var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(!_vm.showExpand),expression:"!showExpand"}],staticClass:"pr-0 py-0",attrs:{"cols":"12","md":_vm.showHelp ? 8 : 11,"sm":12}},[_c('GoBackBtn',{attrs:{"routeToGo":_vm.routeToGo,"tabId":false}}),_c('PageHeader',{staticClass:"pb-1",attrs:{"title":_vm.title}}),_c('v-card',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.procesosActualizacion,"search":_vm.search,"loading":_vm.loading,"show-expand":"","expanded":_vm.expanded,"item-key":"procId"},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item.anulado",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"small":"","color":"primary"}},[_vm._v(" "+_vm._s(item.fechaAnula != null ? _vm.checkIcon : "")+" ")])]}},{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"8"}},[_c('v-text-field',{attrs:{"append-icon":_vm.searchIcon,"label":"Buscar","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{attrs:{"cols":"4","align":"end"}},[(_vm.canCreateProceso)?_c('v-btn',{staticClass:"to-right",attrs:{"color":"primary"},on:{"click":function($event){return _vm.goToNuevoProcesoActualizacion()}}},[_vm._v(" Nuevo ")]):_vm._e()],1)],1)],1)]},proxy:true},{key:"item.data-table-expand",fn:function(ref){
var item = ref.item;
var isExpanded = ref.isExpanded;
return [_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"size":"20"},on:{"click":function($event){return _vm.handleExpansion(item, isExpanded)}}},'v-icon',attrs,false),on),[_vm._v(_vm._s("mdi-chevron-down"))])]}}],null,true)},[_c('span',[_vm._v("Ver filtros aplicados")])])]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('strong',[_vm._v("Filtros aplicados:")]),_vm._v(" "+_vm._s(item.filtrosAplicados)+" ")])],1)],1)]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"size":"20","disabled":item.fechaAnula != null},on:{"click":function($event){return _vm.toggleModalSeeProcesoActualizacion(item.procId)}}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.seeIcon)+" ")])]}}],null,true)},[_c('span',[_vm._v("Ver detalle")])]),(_vm.puedeAnular)?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"size":"20","disabled":item.fechaAnula != null},on:{"click":function($event){return _vm.anularProceso(item.procId)}}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.anularIcon)+" ")])]}}],null,true)},[_c('span',[_vm._v("Anular proceso")])]):_vm._e()]}}],null,true)})],1)],1),(_vm.showIcon)?_c('v-col',{staticClass:"text-right pt-5 px-0",attrs:{"cols":"12","md":!_vm.showHelp ? 1 : _vm.showExpand ? 12 : 4}},[_c('Ayuda',{attrs:{"optionCode":_vm.optionCode,"pantallaTitle":_vm.title},on:{"toggleExpand":function($event){_vm.showExpand = !_vm.showExpand},"toggleHelp":_vm.toggleHelp,"toggleIcon":function($event){return _vm.toggleIcon()}}})],1):_vm._e()],1),_c('DeleteDialog',{attrs:{"titleProp":_vm.titleDelete,"maxWidth":'35%',"confirmButtonText":'Anular',"openDelete":_vm.showDeleteModal,"isLoading":_vm.loadingBtnDelete},on:{"update:openDelete":function($event){_vm.showDeleteModal=$event},"update:open-delete":function($event){_vm.showDeleteModal=$event},"onDeleteItem":function($event){return _vm.confirmAnularProceso()}}}),(_vm.modalVerDetalleActualizacion)?_c('v-dialog',{attrs:{"max-width":"85%","persistent":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.toggleModalSeeProcesoActualizacion($event)}},model:{value:(_vm.modalVerDetalleActualizacion),callback:function ($$v) {_vm.modalVerDetalleActualizacion=$$v},expression:"modalVerDetalleActualizacion"}},[_c('VerDetalleActualizacionImpFijos',{attrs:{"procesoId":_vm.procesoId},on:{"toggleModalSeeProcesoActualizacion":_vm.toggleModalSeeProcesoActualizacion}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }